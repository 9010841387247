import './index.scss'
import LogoS from '../../assets/images/logo-s.png'
import AnimatedLetters from '../AnimatedLetters'
import { Link, NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'

const AddressComponent = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
    return () => clearTimeout(timer)
  }, [])

  const [textToCopy] = useState("EQAArUWShZ_tpI8mwFsPz2OqToJJHxyETCBVhVnGvNq-Lcrk");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("CA copied to clipboard!");
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };


  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div
        data-border="true"
        className='side-container'
      >
        <div
          className="text-zone"
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <h1 className={letterClass}>
            EQAArUWShZ_tpI8mwFsPz2OqToJJHxyETCBVhVnGvNq-Lcrk
          </h1>
          
        </div>
        <div
         style={{
          display: 'flex',
          alignItems: 'center'
         }}
        >
          <button onClick={copyToClipboard} className="flat-button">
            COPY
          </button>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <>
      <div className="nav-bar">
        <Link className="logo" to="/">
          <img src={LogoS} alt="Logo" />
        </Link>
        <AddressComponent />
        <div></div>
      </div>
    </>
  )
}

export default Sidebar
