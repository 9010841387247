/* eslint-disable no-unused-vars */
import LogoS from '../../../assets/images/logo-s.png'
import AnimatedLetters from '../../AnimatedLetters'
import './index.scss'

const Logo = () => {

  return (
    <div className="logo-container">
      <div>
        <img
          className="solid-logo"
          src={LogoS}
          alt="JavaScript, Developer"
        />
        <a 
          className="solid-buy"
          href="https://dedust.io/swap/TON/EQAArUWShZ_tpI8mwFsPz2OqToJJHxyETCBVhVnGvNq-Lcrk" style={{position: "absolute", bottom: "-5%", left: "30%", width: "35%", textAlign: "center", display: "block", backgroundColor: "#0098ea", color: "white"}} className="flat-button" target="_blank" rel="noopener noreferrer">
          BUY
        </a>
      </div>
    </div>
  )
}

export default Logo
